import React from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';
import DisclaimerModal from '../component/DisclaimerModal';

export default function Layout({ pageTitle, children }) {
  let seen = localStorage.getItem('seen');

  return (
    <>
      {seen === null || seen === false ? <DisclaimerModal /> : null}
      <div id="page">
        <Header pageTitle={pageTitle} />
        {children}
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n  .enquiry-page-contact-info ul li:before{\n    color: #bea34d !important;\n  }\n',
          }}
        />
        <Footer />
      </div>
    </>
  );
}
