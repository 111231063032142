import { Routes, Route, BrowserRouter, Outlet } from 'react-router-dom';
import ScrollToTop from '../component/ScrollToTop';
import RoutesList from '../constants/RoutesList';
import Home from '../pages/Home';
import AboutUs from '../pages/AboutUs';
import NewsFeed from '../pages/NewsFeed';
import Careers from '../pages/Careers';
import ContactUs from '../pages/ContactUs';

export const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route element={<Outlet />}>
          <Route index path={RoutesList.home} element={<Home />} />
          <Route path={RoutesList.about} element={<AboutUs />} />
          <Route path={RoutesList.newsFeeds} element={<NewsFeed />} />
          <Route path={RoutesList.careers} element={<Careers />} />
          <Route path={RoutesList.contact} element={<ContactUs />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
