import React from 'react';

const Footer = () => {
  return (
    <footer id="colorlib-footer" role="contentinfo">
      <div className="container" style={{ borderBottom: '2px solid #ffcc54' }}>
        <div className="row row-pb-md">
          <div className="col-md-12">
            <div className="colorlib-contact-info">
              <ul>
                <li className="address" style={{ marginBottom: 0 }}>
                  C-29, Level B2, South Extension Part-II New Delhi – 110049
                </li>
                <li className="phone" style={{ marginBottom: 0 }}>
                  <a
                    href="tel://01140104121"
                    style={{ color: 'rgba(255, 255, 255, 0.5)' }}
                  >
                    011 4010 4121
                  </a>
                  ,{' '}
                  <a
                    href="tel://01126258222"
                    style={{ color: 'rgba(255, 255, 255, 0.5)' }}
                  >
                    011 2625 8222
                  </a>
                </li>
                <li className="email" style={{ marginBottom: 0 }}>
                  <a
                    href="mailto:message@knjpartners.com"
                    style={{ color: 'rgba(255, 255, 255, 0.5)' }}
                  >
                    message@knjpartners.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row copyright">
          <div className="col-md-12 text-center">
            <br />
            <p>
              <small className="block">
                © 2009 K 'n' J Partners. All Rights Reserved.{' '}
              </small>
            </p>
            <p></p>
            <ul className="colorlib-social-icons">
              <li>
                <a href="/">
                  <i className="icon-home" />
                </a>
              </li>
              <li>
                <a href="/about">
                  <i className="icon-info" />
                </a>
              </li>
              <li>
                <a href="/news_feeds">
                  <i className="icon-file" />
                </a>
              </li>
              <li>
                <a href="/careers">
                  <i className="icon-suitcase" />
                </a>
              </li>
              <li>
                <a href="/contact">
                  <i className="icon-mail" />
                </a>
              </li>
            </ul>
            <p />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
