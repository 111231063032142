import React, { useEffect } from 'react';
import Layout from './Layout';
import careers from '../../src/assests/images/careers.jpeg';

function Careers() {
  useEffect(() => {
    document.title = "Careers | K 'n' J Partners";
  }, []);

  return (
    <>
      <Layout pageTitle={'Careers'}>
        <aside id="colorlib-hero" className="js-fullheight">
          <div className="flexslider js-fullheight">
            <ul className="slides">
              <li
                style={{
                  backgroundImage: `url(${careers})`,
                  width: '100%',
                  float: 'left',
                  marginRight: '-100%',
                  position: 'relative',
                  opacity: 1,
                  display: 'block',
                  transition: 'opacity 0.6s ease 0s',
                  zIndex: 2,
                  height: 915,
                }}
                className="flex-active-slide"
              >
                <div className="overlay-gradient" />
                <div className="container">
                  <div className="col-md-10 col-md-offset-1 text-center js-fullheight slider-text animated fadeInUp">
                    <div className="slider-text-inner desc">
                      <h2 className="heading-section">Careers</h2>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </aside>
        <div id="colorlib-contact">
          <div className="container">
            <div className="row">
              <div
                className="col-md-11 col-md-push-1 colorlib-heading"
                style={{ marginBottom: '2em' }}
              >
                <h2>Careers at K 'n' J Partners</h2>
                <p style={{ textAlign: 'justify' }}>
                  Our work environment provides perfect combination of challenge
                  &amp; to help you grow on the job. We provide jobs for law
                  graduates in the form of dynamic platform to enable them to
                  grow, both professionally &amp; personally, in their
                  respective domains by working together as a team.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  We actively look for professionals who are driven to make a
                  difference through their legal practice. We believe that our
                  people are our greatest asset &amp; they come together to form
                  a winning team.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  If you are looking to build a promising career at K 'n' J
                  Partners, join us.
                </p>
                <hr />
              </div>

              <form
                encType="multipart/form-data"
                action="/careers"
                acceptcharset="UTF-8"
                method="post"
              >
                <input name="utf8" type="hidden" defaultvalue="✓" />
                <input
                  type="hidden"
                  name="authenticity_token"
                  defaultvalue="ewIf2ZjN/RWxceU/+pX8jAzOWuV3PgE3FGyKFyN6J4HG6k7KQSvvLVRFxpE0mdIo6bjocyTKnYWYetbtNtbAuw=="
                />
                <div className="col-md-5 col-md-push-1 animate-box">
                  <div className="row form-group">
                    <div className="col-md-12">
                      <select
                        name="career[position]"
                        id="career_position"
                        className="form-control"
                        required="required"
                      >
                        <option value="">Position</option>
                        <option value="Full Time">Full Time</option>
                        <option value="Internship">Internship</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-12">
                      <input
                        type="text"
                        id="name"
                        name="career[name]"
                        className="form-control"
                        placeholder="Your name"
                        required="required"
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-12">
                      <input
                        type="email"
                        id="email"
                        name="career[email]"
                        className="form-control"
                        placeholder="Your email address"
                        required="required"
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-12">
                      <input
                        type="tel"
                        id="phone"
                        name="career[phone]"
                        className="form-control"
                        placeholder="Your phone number"
                        required="required"
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-12">
                      <input
                        type="text"
                        id="current_location"
                        name="career[current_location]"
                        className="form-control"
                        placeholder="Your current location"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-md-offset-1 animate-box">
                  <div className="row form-group">
                    <div className="col-md-12">
                      <input
                        type="text"
                        id="professional_experience"
                        name="career[professional_experience]"
                        className="form-control"
                        placeholder="Your professional experience"
                        required="required"
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-12">
                      <input
                        type="text"
                        id="current_or_last_employer"
                        name="career[current_or_last_employer]"
                        className="form-control"
                        placeholder="Your current/last employer"
                        required="required"
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-12">
                      <input
                        type="text"
                        id="designation"
                        name="career[designation]"
                        className="form-control"
                        placeholder="Your current designation"
                        required="required"
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-6">
                      <input
                        type="text"
                        id="degree_held"
                        name="career[degree_held]"
                        className="form-control"
                        placeholder="Your professional degree"
                        required="required"
                      />
                    </div>
                    <div className="col-md-6">
                      <select
                        name="career[passing_year]"
                        id="career_passing_year"
                        className="form-control"
                        required="required"
                      >
                        <option value="">Passing year</option>
                        <option value="{1994}">1994</option>
                        <option value="{1995}">1995</option>
                        <option value="{1996}">1996</option>
                        <option value="{1997}">1997</option>
                        <option value="{1998}">1998</option>
                        <option value="{1999}">1999</option>
                        <option value="{2000}">2000</option>
                        <option value="{2001}">2001</option>
                        <option value="{2002}">2002</option>
                        <option value="{2003}">2003</option>
                        <option value="{2004}">2004</option>
                        <option value="{2005}">2005</option>
                        <option value="{2006}">2006</option>
                        <option value="{2007}">2007</option>
                        <option value="{2008}">2008</option>
                        <option value="{2009}">2009</option>
                        <option value="{2010}">2010</option>
                        <option value="{2011}">2011</option>
                        <option value="{2012}">2012</option>
                        <option value="{2013}">2013</option>
                        <option value="{2014}">2014</option>
                        <option value="{2015}">2015</option>
                        <option value="{2016}">2016</option>
                        <option value="{2017}">2017</option>
                        <option value="{2018}">2018</option>
                        <option value="{2019}">2019</option>
                        <option value="{2020}">2020</option>
                        <option value="{2021}">2021</option>
                        <option value="{2022}">2022</option>
                        <option value="{2023}">2023</option>
                        <option value="{2024}">2024</option>
                      </select>
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-6">
                      <input
                        type="text"
                        id="name_of_institute"
                        name="career[name_of_institute]"
                        className="form-control"
                        placeholder="Your institute name"
                        required="required"
                      />
                    </div>
                    <div className="col-md-6">
                      <select
                        name="career[grade_in_percentage]"
                        id="career_grade_in_percentage"
                        className="form-control"
                        required="required"
                      >
                        <option value="">Grade in %</option>
                        <option value="{1}">1</option>
                        <option value="{2}">2</option>
                        <option value="{3}">3</option>
                        <option value="{4}">4</option>
                        <option value="{5}">5</option>
                        <option value="{6}">6</option>
                        <option value="{7}">7</option>
                        <option value="{8}">8</option>
                        <option value="{9}">9</option>
                        <option value="{10}">10</option>
                        <option value="{11}">11</option>
                        <option value="{12}">12</option>
                        <option value="{13}">13</option>
                        <option value="{14}">14</option>
                        <option value="{15}">15</option>
                        <option value="{16}">16</option>
                        <option value="{17}">17</option>
                        <option value="{18}">18</option>
                        <option value="{19}">19</option>
                        <option value="{20}">20</option>
                        <option value="{21}">21</option>
                        <option value="{22}">22</option>
                        <option value="{23}">23</option>
                        <option value="{24}">24</option>
                        <option value="{25}">25</option>
                        <option value="{26}">26</option>
                        <option value="{27}">27</option>
                        <option value="{28}">28</option>
                        <option value="{29}">29</option>
                        <option value="{30}">30</option>
                        <option value="{31}">31</option>
                        <option value="{32}">32</option>
                        <option value="{33}">33</option>
                        <option value="{34}">34</option>
                        <option value="{35}">35</option>
                        <option value="{36}">36</option>
                        <option value="{37}">37</option>
                        <option value="{38}">38</option>
                        <option value="{39}">39</option>
                        <option value="{40}">40</option>
                        <option value="{41}">41</option>
                        <option value="{42}">42</option>
                        <option value="{43}">43</option>
                        <option value="{44}">44</option>
                        <option value="{45}">45</option>
                        <option value="{46}">46</option>
                        <option value="{47}">47</option>
                        <option value="{48}">48</option>
                        <option value="{49}">49</option>
                        <option value="{50}">50</option>
                        <option value="{51}">51</option>
                        <option value="{52}">52</option>
                        <option value="{53}">53</option>
                        <option value="{54}">54</option>
                        <option value="{55}">55</option>
                        <option value="{56}">56</option>
                        <option value="{57}">57</option>
                        <option value="{58}">58</option>
                        <option value="{59}">59</option>
                        <option value="{60}">60</option>
                        <option value="{61}">61</option>
                        <option value="{62}">62</option>
                        <option value="{63}">63</option>
                        <option value="{64}">64</option>
                        <option value="{65}">65</option>
                        <option value="{66}">66</option>
                        <option value="{67}">67</option>
                        <option value="{68}">68</option>
                        <option value="{69}">69</option>
                        <option value="{70}">70</option>
                        <option value="{71}">71</option>
                        <option value="{72}">72</option>
                        <option value="{73}">73</option>
                        <option value="{74}">74</option>
                        <option value="{75}">75</option>
                        <option value="{76}">76</option>
                        <option value="{77}">77</option>
                        <option value="{78}">78</option>
                        <option value="{79}">79</option>
                        <option value="{80}">80</option>
                        <option value="{81}">81</option>
                        <option value="{82}">82</option>
                        <option value="{83}">83</option>
                        <option value="{84}">84</option>
                        <option value="{85}">85</option>
                        <option value="{86}">86</option>
                        <option value="{87}">87</option>
                        <option value="{88}">88</option>
                        <option value="{89}">89</option>
                        <option value="{90}">90</option>
                        <option value="{91}">91</option>
                        <option value="{92}">92</option>
                        <option value="{93}">93</option>
                        <option value="{94}">94</option>
                        <option value="{95}">95</option>
                        <option value="{96}">96</option>
                        <option value="{97}">97</option>
                        <option value="{98}">98</option>
                        <option value="{99}">99</option>
                        <option value="{100}">100</option>
                      </select>
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-12"></div>
                  </div>
                </div>
                <div className="col-md-11 col-md-offset-1">
                  <div className="row">
                    <div className="col-md-12 form-group">
                      <textarea
                        id="skills"
                        name="career[skills]"
                        cols={30}
                        rows={10}
                        className="form-control"
                        placeholder="Your skills..."
                        required="required"
                        defaultValue={''}
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-12">
                      <label>Upload file</label>
                      <input type="file" name="career[resume]" />
                    </div>
                  </div>
                  <div className="form-group">
                    <br />
                    <input
                      type="submit"
                      defaultValue="Send"
                      className="btn btn-primary"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Careers;
