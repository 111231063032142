import React from 'react';

const Slider = (slideImage, captionTitle, captionSubtitle, slideNumber) => {
  return (
    <div className={`item ${slideNumber === 0 ? 'active' : ''}`}>
      <img
        src={slideImage}
        alt="..."
        style={{ objectFit: 'cover', width: '100%', height: 915 }}
      />
      <div className="carousel-caption" style={styles.carouselCaption}>
        <h1 className="custom-slider" style={styles.h1}>
          {captionTitle}
        </h1>
        <h2 style={styles.h2}>{captionSubtitle}</h2>
      </div>
    </div>
  );
};

const styles = {
  carouselCaption: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    left: '0%',
    right: '0%',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  h1: {
    marginBottom: '20px',
    fontSize: '44px',
    width: '40%',
    lineHeight: 1.3,
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: '1px',
    color: 'white',
    fontFamily: 'Playfair Display", Georgia, serif',
  },
  h2: {
    fontSize: '20px',
    lineHeight: 1.5,
    marginBottom: 30,
    color: 'white',
    fontFamily: 'Playfair Display", Georgia, serif',
  },
};

export default Slider;
