import React, { useEffect } from 'react';
import Layout from './Layout';
import news from '../../src/assests/images/news-feed.jpeg';

function NewsFeed() {
  useEffect(() => {
    document.title = "News Feeds | K 'n' J Partners";
  }, []);

  return (
    <>
      <Layout pageTitle={'NewsFeed'}>
        <aside id="colorlib-hero" className="js-fullheight">
          <div className="flexslider js-fullheight">
            <ul className="slides">
              <li
                style={{
                  backgroundImage: `url(${news})`,
                  width: '100%',
                  float: 'left',
                  marginRight: '-100%',
                  position: 'relative',
                  opacity: 1,
                  display: 'block',
                  transition: 'opacity 0.6s ease 0s',
                  zIndex: 2,
                  height: 915,
                }}
                className="flex-active-slide"
              >
                <div className="overlay-gradient" />
                <div className="container">
                  <div className="col-md-10 col-md-offset-1 text-center js-fullheight slider-text animated fadeInUp">
                    <div className="slider-text-inner desc">
                      <h2 className="heading-section">News Feeds</h2>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </aside>
        <div id="colorlib-about" style={{ paddingBottom: '0.5em !important' }}>
          <div className="container">
            <div className="row animate-box">
              <div className="col-md-10 colorlib-heading">
                <h3>No data found!</h3>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default NewsFeed;
