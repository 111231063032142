import React, { useEffect } from 'react';
import Layout from './Layout';
import '../assests/bootstrap-custom.css';
import Slider from '../component/Slider';

function Home() {
  useEffect(() => {
    document.title = "Home | K 'n' J Partners";
  }, []);

  return (
    <>
      <Layout pageTitle="Home">
        <div
          id="carousel-example-generic"
          className="carousel slide"
          data-ride="carousel"
        >
          <ol className="carousel-indicators">
            <li
              data-target="#carousel-example-generic"
              data-slide-to="0"
              className="active"
            ></li>
            <li data-target="#carousel-example-generic" data-slide-to="1"></li>
            <li data-target="#carousel-example-generic" data-slide-to="2"></li>
          </ol>

          <div className="carousel-inner" role="listbox">
            {Slider(
              require('../../src/assests/images/home.jpeg'),
              `“Punishment is justice for the unjust”`,
              'Saint Augustine',
              0
            )}
            {Slider(
              require('../../src/assests/images/home.jpeg'),
              '“The safety of the people shall be the highest law”',
              'Marcus Tullius Cicero',
              1
            )}
            {Slider(
              require('../../src/assests/images/home.jpeg'),
              '“IT IS NOT WISDOM BUT AUTHORITY THAT MAKES A LAW”',
              'Thomas Hobbes',
              2
            )}
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Home;
