import React, { useEffect } from 'react';
import Layout from './Layout';
import contact from '../../src/assests/images/contact.jpeg';

function ContactUs() {
  useEffect(() => {
    document.title = "Contact Us | K 'n' J Partners";
  }, []);
  return (
    <>
      <Layout pageTitle={'Contact'}>
        <aside id="colorlib-hero" className="js-fullheight">
          <div className="flexslider js-fullheight">
            <ul className="slides">
              <li
                style={{
                  backgroundImage: `url(${contact})`,
                  width: '100%',
                  float: 'left',
                  marginRight: '-100%',
                  position: 'relative',
                  opacity: 1,
                  display: 'block',
                  transition: 'opacity 0.6s ease 0s',
                  zIndex: 2,
                  height: 915,
                }}
                className="flex-active-slide"
              >
                <div className="overlay-gradient" />
                <div className="container">
                  <div className="col-md-10 col-md-offset-1 text-center js-fullheight slider-text animated fadeInUp">
                    <div className="slider-text-inner desc">
                      <h2 className="heading-section">Contact Us</h2>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </aside>
        <div id="colorlib-contact">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2"></div>
              <div className="col-md-5 col-md-push-1 animate-box enquiry-page-contact-info">
                <div className="colorlib-contact-info">
                  <h3>Contact Information</h3>
                  <ul>
                    <li className="address">
                      C-29, Level B2,
                      <br /> South Extension Part-II <br /> New Delhi – 110049
                    </li>
                    <li className="phone">
                      <a href="tel://01140104121" style={{ color: '#828282' }}>
                        011 4010 4121
                      </a>
                      ,{' '}
                      <a href="tel://01126258222" style={{ color: '#828282' }}>
                        011 2625 8222
                      </a>
                    </li>
                    <li className="email">
                      <a
                        href="mailto:message@knjpartners.com"
                        style={{ color: '#828282' }}
                      >
                        message@knjpartners.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 animate-box">
                <h3>Send A Message</h3>
                <form action="/contact" acceptCharset="UTF-8" method="post">
                  <input name="utf8" type="hidden" defaultValue="✓" />
                  <input
                    type="hidden"
                    name="authenticity_token"
                    defaultValue="Scyv4KlX0HWQo6FuH+hHvA6vz5fjq9IlNpkcIJMsSAE/XkoHUnCjNMk4vAvD6sqYXJ6jql4p8C30SHximnNCEg=="
                  />
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          type="text"
                          name="enquiry[firstname]"
                          id="fname"
                          className="form-control"
                          placeholder="Your firstname"
                          required="required"
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          name="enquiry[lastname]"
                          id="lname"
                          className="form-control"
                          placeholder="Your lastname"
                          required="required"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row form-group">
                    <div className="col-md-12">
                      <input
                        type="text"
                        id="email"
                        name="enquiry[email]"
                        className="form-control"
                        placeholder="Your email address"
                        required="required"
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-12">
                      <input
                        type="text"
                        id="subject"
                        name="enquiry[subject]"
                        className="form-control"
                        placeholder="Your subject of this message"
                        required="required"
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-12">
                      <textarea
                        id="message"
                        name="enquiry[message]"
                        cols={30}
                        rows={10}
                        className="form-control"
                        placeholder="message..."
                        required="required"
                        defaultValue={''}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="submit"
                      defaultValue="Send Message"
                      className="btn btn-primary"
                    />
                  </div>
                </form>{' '}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="colorlib-map col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h3>Find Us On Map</h3>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.0870213619!2d77.21878515015705!3d28.56714948235751!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce25cdac32487%3A0x4d0aede51bf2ac07!2sK+'n'+J+Partners%2C+Advocates+%26+Solicitors!5e0!3m2!1sen!2sin!4v1536650230810"
              width="100%"
              height={400}
              frameBorder={0}
              style={{ border: 0 }}
              allowFullScreen=""
            />
          </div>
        </div>
      </Layout>
    </>
  );
}

export default ContactUs;
