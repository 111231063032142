import React from 'react';

const DisclaimerModal = () => {
  let seen = localStorage.getItem('seen');

  const haveSeenModal = () => {
    localStorage.setItem('seen', true);
    window.location.reload();
  };

  return (
    <>
      <div
        className={`modal ${seen === null || seen === false ? 'show' : 'hide'}`}
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ zIndex: 2 }}
      >
        <div class="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <center>
                <h4
                  className="modal-title"
                  style={{ textTransform: 'uppercase' }}
                >
                  Disclaimer
                </h4>
              </center>
            </div>
            <div className="modal-body">
              <p style={{ textAlign: 'justify', fontSize: 12 }}>
                Under the rules of the Bar Council of India, Advocates including
                K ‘n’ J Partners (The “Firm”), are prohibited from soliciting
                work or advertising. By clicking “I Agree” below and accessing
                our website, you acknowledge and confirm that you are seeking
                information about us out of your own accord and there has been
                no advertisement, personal communication, solicitation,
                invitation or inducement of any sort whatsoever from us or any
                of our members to solicit any work through this website in any
                manner. The purpose of this website is to provide the user with
                basic knowledge about the Firm and access to the website shall
                solely be at your own behest for informational purposes only,
                which should not be interpreted as soliciting or advisement and
                would not create any lawyer client alliance. This website is not
                intended to be a source of advertising or solicitation and the
                contents hereof should not be construed as legal advice in any
                manner whatsoever. The Firm and its associates are not liable
                for any consequence of any action taken by the user relying on
                information provided under this website.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary accept-button btn btn-success pull-left"
                data-dismiss="modal"
                onClick={haveSeenModal}
              >
                I Agree
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DisclaimerModal;
