import React from 'react';
import { images } from '../../constants/images';
import { Link } from 'react-router-dom';

const Header = ({ pageTitle }) => {
  return (
    <>
      <a
        href="#"
        className="js-colorlib-nav-toggle colorlib-nav-toggle colorlib-nav-white"
        style={{ zIndex: 1 }}
      >
        <i></i>
      </a>
      <div id="colorlib-offcanvas">
        <ul>
          <li className={pageTitle === 'Home' ? 'active' : null}>
            <Link to={'/'}>Home</Link>
          </li>
          <li className={pageTitle === 'About' ? 'active' : null}>
            <Link to={'/about'}>About us</Link>
          </li>
          <li className={pageTitle === 'NewsFeed' ? 'active' : null}>
            <Link to={'/news_feeds'}>News Feed</Link>
          </li>
          <li className={pageTitle === 'Careers' ? 'active' : null}>
            <Link to={'/careers'}>Careers</Link>
          </li>
          <li className={pageTitle === 'Contact' ? 'active' : null}>
            <Link to={'/contact'}>Contact Us</Link>
          </li>
        </ul>
      </div>

      <nav className="colorlib-nav" role="navigation">
        <div className="top-menu">
          <div className="container">
            <div className="row">
              <div className="col-md-5" style={{ alignContent: 'center' }}>
                <div id="colorlib-logo">
                  <a href="/">
                    <img src={images.logo} alt="Test logo" />
                  </a>
                </div>
              </div>
              <div className="col-md-7 text-right menu-1">
                <ul>
                  <li className={pageTitle === 'Home' ? 'active' : null}>
                    <Link to={'/'}>Home</Link>
                  </li>
                  <li className={pageTitle === 'About' ? 'active' : null}>
                    <Link to={'/about'}>About us</Link>
                  </li>
                  <li className={pageTitle === 'NewsFeed' ? 'active' : null}>
                    <Link to={'/news_feeds'}>News Feed</Link>
                  </li>
                  <li className={pageTitle === 'Careers' ? 'active' : null}>
                    <Link to={'/careers'}>Careers</Link>
                  </li>
                  <li className={pageTitle === 'Contact' ? 'active' : null}>
                    <Link to={'/contact'}>Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
