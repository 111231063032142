import React from 'react';
import { Helmet } from 'react-helmet';
import { Router } from './routes';

const App = () => (
  <>
    <AppHead />
    <Router />
  </>
);

const AppHead = () => {
  return (
    <Helmet>
      <base href="/" />
      <meta charset="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <title>{document.title}</title>
    </Helmet>
  );
};

export default App;
