import React, { useEffect } from 'react';
import Layout from './Layout';
import about from '../../src/assests/images/about.jpeg';

function AboutUs() {
  useEffect(() => {
    document.title = "About | K 'n' J Partners";
  }, []);

  return (
    <>
      <Layout pageTitle={'About'}>
        <aside id="colorlib-hero" className="js-fullheight">
          <div className="flexslider js-fullheight">
            <ul className="slides">
              <li
                style={{
                  backgroundImage: `url(${about})`,
                  width: '100%',
                  float: 'left',
                  marginRight: '-100%',
                  position: 'relative',
                  opacity: 1,
                  display: 'block',
                  transition: 'opacity 0.6s ease 0s',
                  zIndex: 2,
                  height: 915,
                }}
                className="flex-active-slide"
              >
                <div className="overlay-gradient" />
                <div className="container">
                  <div className="col-md-10 col-md-offset-1 text-center js-fullheight slider-text animated fadeInUp">
                    <div className="slider-text-inner desc">
                      <h2 className="heading-section">About Us</h2>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </aside>

        <div id="colorlib-about" style={{ paddingBottom: '0.5em !important' }}>
          <div className="container">
            <div className="row animate-box">
              <div className="col-md-10 col-md-offset-1 colorlib-heading">
                <p style={{ textAlign: 'justify' }}>
                  <b>K ‘n’ J Partners</b> is a Delhi based full service law firm
                  engaged in providing legal services majorly to corporate and
                  institutional clients on PAN India basis.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  It has been Firm’s principal commitment to provide quality
                  service to its clients. The Firm takes pride in the talent of
                  its associates, the depth of their expertise and the teamwork
                  employed in responding to clients' needs. Our counsels work
                  and practice specially, to help clients in avoiding legal
                  problems, resolving disputes, and achieving their business
                  oriented goals. By hearing out to clients and understanding
                  their business objectives, our Firm is able to staff each
                  assignment efficiently and cost-effectively. Whether an
                  individual, a small business in an emerging industry or a
                  diversified multinational corporation or institution, each of
                  our clients represents an important relationship to the Firm
                  to which we dedicate our best resources, attention, quality of
                  service and delivery.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  The counsel of the Firm have provided services to various
                  Fortune 500 companies, other multinational companies, banks,
                  domestic companies and institutions including financial
                  institutions. The Firm practices in all aspects of contentious
                  and non-contentious matters including contracts, commercial
                  and contractual obligations, arbitration, general corporate
                  counseling, investment structuring, regulatory and legislative
                  matters, commercial lending and other financial transactions,
                  telecommunications, real estate, taxation, corporate law
                  including formation of company, joint ventures, merger,
                  strategic planning, due diligence, negotiating for and behalf
                  of clients and representation before government officials such
                  as Reserve Bank of India, Registrar of Companies, Foreign
                  Investment Promotion Board, Trade Mark Registry, laws
                  pertaining to banking, securities, investments, partnerships,
                  customs, information technology, cyber laws, maritime and
                  aviation, intellectual property etc.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  Apart from the above a dedicated team of the Firm is also
                  engaged in all aspects of general litigation amongst other
                  matters including recoveries, contractual disputes, consumer
                  complaints, restrictive trade practices, employer-employee
                  disputes, white collar crimes, arbitrations and cross border
                  arbitrations. Our team possesses well-proven expertise in
                  handling complex transactions and is constantly aiming at
                  optimum results operating on an international time frame.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  The Firm’s dedication to the client's interest means that we
                  strive for excellence as well as appreciate the commercial
                  environment in which our clients operate. We combine a
                  personal approach with high professional standards and aim to
                  provide comprehensive legal services to our corporate,
                  financial, institutional and commercial clients. With a
                  committed international corporate and commercial practice, we
                  have assisted clients entering the Indian market, advising
                  them on the most efficient market entry strategy and tax
                  efficient structure.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  The Firm provides valuable insights regarding the prevailing
                  economic and commercial climate of India. In the fast-paced
                  legal and business environment, we quickly provide our clients
                  with clear and practical advice to facilitate their commercial
                  activities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default AboutUs;
